@import '../styles/_colours.scss';

@font-face {
  font-family: apple-chancery;
  src: url('../../fonts/Apple Chancery.ttf');
}

html,body {
  height:99%;
}
#app {
  width: 100%;
  display:flex;
  justify-content: center;
}
.container {
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  height:100%;
  align-items: stretch;
  max-width:360px;
  width: 100%;
}
@media screen and (min-width: 361px) {
  .container {
    max-width: 412px;
  }
}
@media screen and (min-width: 413px) {
  .container {
    max-width: 940px;
  }
}

.toolbar {
  background-color: darken(pink,5);
  height:140px;
  display  :flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  z-index: 99;
  border: 1px solid black;
}
@media screen and (min-width: 413px) {
  .toolbar{
    height:100px;
  }
}
.toolbar>input {
  height:70%;
  margin-left: 15px;
}
.toolbar>input:active {
}
.button-bar {
  margin-left: 10px;
}

.button-bar > input {
  margin: 5px;
}

.main-header {
  font-size: 1.7em;
  margin  :5px;
  margin-right: 20px;
  font-family: apple-chancery;
}
.footer {
  background-color: darken(pink,5);
  height: 40px;
  border: 1px solid black;
}
.timeline-time-slot {
  border: 1px solid black;
  height:60px;
  display:grid;
  grid-template-columns: 1.6fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  box-sizing: border-box;
}

.timeline-time-slot-hour {
  font-size: 2.5em;
  grid-row-start: 1;
  grid-row-end: 5;
  align-self: center;
  justify-self: center;
  width: 45px;
  text-align: center;
}

.timeline-time-slot-minute {
  font-size: 0.7em;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  text-align: center;
  width:25px;
  height:15px;
}

.timeline-time-slot-minute:nth-child(5) {
  border-bottom: none;
}

.tipsy-submit-button {
  background-color: lightpink;
  border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 3px 3px 4px #666;
}

.tipsy-submit-button:active {
  box-shadow: 1px 1px 2px #999;
  transform: translateY(2px);
};

.tipsy-button {
  background-color: lightgrey;
  border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 3px 3px 4px #666;
  cursor: pointer;
}

.tipsy-button:active {
  box-shadow: 1px 1px 2px #999;
  transform: translateY(2px);
};

.tipsy-button-pink {
  background-color: lightpink;
}

.tipsy-input {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 3px #666;
}

.tipsy-app {
  width:940px;
  display: flex;
  justify-content: center;
}

select {
  border: 1px solid black;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 3px #666;
}

.hidden-button{
  border:none !important;
  background-color: transparent;
  width: 100%;
  height:100%;
  cursor: pointer;
  outline: none;
}

.hidden-button:active{
  border:none !important;

}

.mobile-nav-bar{
  background-color: lightgrey;
  height:30px;
}

.mobile-list-button > i:after {
  content : "\2261";
  font-weight: bold;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.nav-item-active {
  color : white !important;
}

.triangle-right {
  width:0px;
  height:0px;
  border-left: 10px solid $color6 ;
  border-top: 10px solid transparent ;
  border-bottom: 10px solid transparent ;
  border-right: 10px solid transparent ;
  background-color: transparent;
  padding : 0;
  margin: 3px 0 3px 0;
}

.triangle-right:focus {
  outline: none !important;
}

.triangle-left {
  width:0px;
  height:0px;
  border-right: 10px solid $color6 ;
  border-top: 10px solid transparent ;
  border-bottom: 10px solid transparent ;
  border-left: 10px solid transparent ;
  background-color: transparent;
  padding : 0;
  margin: 3px 0 3px 0;
}

.triangle-left:focus {
  outline: none !important;
}
